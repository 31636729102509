import {
  IComplete,
  IApproveLeadDto,
} from "@/customtypes/service/operation.interface";
import {
  IDeliveryNotesDto,
  ResponseOperation,
} from "@/customtypes/service/operator.interface";
import {
  IErrorResponse,
  ISuccessResponse,
} from "@/customtypes/service/request.interface";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IReassign } from "./operator.service";

const API_URL = process.env.REACT_APP_API;

export const DeliveryNoteService = createApi({
  reducerPath: "DeliveryNote",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
  }),
  tagTypes: ["dn"],
  endpoints: (builder) => ({
    findAllDn: builder.query<ResponseOperation, { token: string }>({
      query: ({ token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/operation/dn",
          method: "GET",
        };
      },
      providesTags: ["dn"],
    }),

    updateDn: builder.mutation<
      IErrorResponse | ISuccessResponse,
      { data: IDeliveryNotesDto; token: string; operationId: string }
    >({
      query: (data) => {
        return {
          headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-type": "application/json",
          },
          url: `/operation/dn/${data.operationId}`,
          method: "PATCH",
          body: data.data,
        };
      },
      invalidatesTags: ["dn"],
    }),

    completeDn: builder.mutation<ResponseOperation, IComplete>({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/dn/${data.operationId}/complete`,
          method: "GET",
        };
      },
      invalidatesTags: ["dn"],
    }),

    reassignDn: builder.mutation<ResponseOperation, IReassign>({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/dn/${data.operationId}/reassign`,
          method: "PATCH",
          body: {
            deadline: data.deadline,
            deadLineDated: data.deadLineDated,
          },
        };
      },
      invalidatesTags: ["dn"],
    }),
    approveDn: builder.mutation<ResponseOperation, IApproveLeadDto>({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/dn/${data.operationId}/approve`,
          method: "PATCH",
          body: {
            deadline: data.deadline,
            deadLineDated: data.deadLineDated,
            assignTo: data.assignTo,
            userId: data.userId,
          },
        };
      },
      invalidatesTags: ["dn"],
    }),
  }),
});

export const {
  useApproveDnMutation,
  useFindAllDnQuery,
  useCompleteDnMutation,
  useUpdateDnMutation,
  useReassignDnMutation,
} = DeliveryNoteService;
