import { IComplete } from "@/customtypes/service/operation.interface";
import { ResponseOperation } from "@/customtypes/service/operator.interface";
import {
  IErrorResponse,
  ISuccessResponse,
} from "@/customtypes/service/request.interface";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IReassign } from "./operator.service";

const API_URL = process.env.REACT_APP_API;

export const ProjectCompletionService = createApi({
  reducerPath: "ProjectCompletion",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
  }),
  tagTypes: ["pcc"],
  endpoints: (builder) => ({
    findAllPcc: builder.query<ResponseOperation, { token: string }>({
      query: ({ token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/operation/pcc",
          method: "GET",
        };
      },
      providesTags: ["pcc"],
    }),

    updatePcc: builder.mutation<
      IErrorResponse | ISuccessResponse,
      {
        data: FormData;
        token: string;
        operationId: string;
      }
    >({
      query: (data) => {
        return {
          headers: {
            Authorization: `Bearer ${data.token}`,
            // "Content-type": "application/json",
          },
          url: `/operation/pcc/${data.operationId}`,
          method: "PATCH",
          body: data.data,
          formData: true,
        };
      },
      invalidatesTags: ["pcc"],
    }),

    completePcc: builder.mutation<ResponseOperation, IComplete>({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/pcc/${data.operationId}/complete`,
          method: "GET",
        };
      },
      invalidatesTags: ["pcc"],
    }),

    reassignPcc: builder.mutation<ResponseOperation, IReassign>({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/pcc/${data.operationId}/reassign`,
          method: "PATCH",
          body: {
            deadline: data.deadline,
            deadLineDated: data.deadLineDated,
          },
        };
      },
      invalidatesTags: ["pcc"],
    }),

    approvePcc: builder.mutation<
      ResponseOperation,
      { operationId: string; token: string }
    >({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/pcc/${data.operationId}/approve`,
          method: "PATCH",
        };
      },
      invalidatesTags: ["pcc"],
    }),
  }),
});

export const {
  useFindAllPccQuery,
  useApprovePccMutation,
  useReassignPccMutation,
  useCompletePccMutation,
  useUpdatePccMutation,
} = ProjectCompletionService;
