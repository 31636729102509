import { FC, lazy } from "react";
import { useAppSelector } from "../../src/redux/hooks/redux.hooks";
import { PermissionFlag } from "../../types/service/user.interface";

import NotFound from "./NotFound";

const LoginRoutes = lazy(() => import("../../src/Routes/Login.routes"));

const Admin = lazy(() => import("../../features/Admin/pages/Admin"));

const Moderator = lazy(
  () => import("../../features/departments/sell/Moderator/pages/Moderator"),
);

const OperationModerator = lazy(
  () => import("../../features/departments/operation/manager/pages/Index"),
);

const Seller = lazy(
  () => import("../../features/departments/sell/seller/pages/User"),
);

const OperatorUser = lazy(
  () => import("../../features/departments/operation/operator/pages/Operator"),
);

const PrivateRoute: FC = () => {
  const { isAuthenticated, user } = useAppSelector((state) => state.auth);

  // useEffect(() => {
  //   // set the user ID when the component mounts
  //   validateToken({ token }).then((res: any) => {
  //     if (
  //       res?.error &&
  //       res?.error?.data &&
  //       Array.isArray((res as any).error.data.error)
  //     ) {
  //       let errArray = (res as any).error.data.error;
  //       if (errArray[0] === "jwt expired") {
  //         dispatch(logout());
  //         navigate("/login");
  //       }
  //     }

  //     if (res && res?.data && res?.data?.success) {
  //       toast.success((res as any).data.message, {
  //         position: "top-right",
  //       });
  //     }
  //   });
  // }, [dispatch, navigate, token, validateToken]);

  if (isAuthenticated) {
    switch (user.permissionFlag) {
      case PermissionFlag[PermissionFlag.ADMIN]:
        return <Admin />;
      case PermissionFlag[PermissionFlag.MODERATOR]:
        return <Moderator />;
      case PermissionFlag[PermissionFlag.OPERATION_MODERATOR]:
        return <OperationModerator />;
      case PermissionFlag[PermissionFlag.USER]:
        return <Seller userID={user.userID} />;
      case PermissionFlag[PermissionFlag.OPERATION_USER]:
        return <OperatorUser />;
      default:
        return <NotFound />;
    }
  } else return <LoginRoutes />;
};

export default PrivateRoute;
