import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IStatistics } from "../interface/statistics.interface";

const API_URL = process.env.REACT_APP_API;

export const StatisticsService = createApi({
  reducerPath: "moderator-statistics",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ["statistics"],
  endpoints: (builder) => ({
    // getStatics --------------------------------->
    getMStatistics: builder.query<IStatistics, { token: string }>({
      query: ({ token }) => {
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          url: "/moderator/statistics",
          method: "GET",
        };
      },
      providesTags: ["statistics"],
    }),
  }),
});

export const { useGetMStatisticsQuery } = StatisticsService;
