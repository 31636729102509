import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_URL = process.env.REACT_APP_API;

export const CRMService = createApi({
  reducerPath: "crm",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: [
    "create-lead",
    "leads",
    "survey",
    "create-survey",
    "quote",
    "create-quote",
    "meeting",
    "create-meeting",
    "closing",
  ],
  endpoints: () => ({}),
});
