import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    selectedUserID: "",
    selectedBranchID: "",
    showSearchFilter: true,
  },
  reducers: {
    setSelectedUserID: (state, action: PayloadAction<{ id: string }>) => {
      state.selectedUserID = action.payload.id;
    },
    setSelectedBranchID: (state, action: PayloadAction<{ id: string }>) => {
      state.selectedBranchID = action.payload.id;
    },
    setDateFilterVisibility: (
      state,
      action: PayloadAction<{ value: boolean }>,
    ) => {
      state.showSearchFilter = action.payload.value;
    },
  },
});

export const {
  setSelectedUserID,
  setDateFilterVisibility,
  setSelectedBranchID,
} = appSlice.actions;
