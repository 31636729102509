import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IInitialState {
  date: string;
  year: string;
  month: string;
  day: string;
}

const initialState: IInitialState = {
  date: "",
  year: "",
  month: "",
  day: "",
};

export const dateFilterSlice = createSlice({
  name: "date-filter",
  initialState,
  reducers: {
    onDayChange: (state, action: PayloadAction<{ day: string }>) => {
      state.day = action.payload.day;
    },
    onMonthChange: (state, action: PayloadAction<{ month: string }>) => {
      state.month = action.payload.month;
    },
    onYearChange: (state, action: PayloadAction<{ year: string }>) => {
      state.year = action.payload.year;
    },

    onDateChange: (state, action: PayloadAction<{ date: string }>) => {
      state.date = action.payload.date;
    },
  },
});

export const { onDayChange, onDateChange, onMonthChange, onYearChange } =
  dateFilterSlice.actions;
export const dateFilterSliceReducer = dateFilterSlice.reducer;
