import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_URL = process.env.REACT_APP_API;

export const AnnouncementService = createApi({
  reducerPath: "announcement",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ["announcement"],
  endpoints: (builder) => ({
    // login --------------------------------->
    createAnnouncement: builder.mutation<
      any,
      { formData: FormData; token: string }
    >({
      query: ({ formData, token }) => {
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          url: "/announcement",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["announcement"],
    }),
  }),
});

export const { useCreateAnnouncementMutation } = AnnouncementService;
