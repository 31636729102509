import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { branchModeratorUser } from "../../../types/admin/branch&moderator&user";
import {
  IDepartment,
  IDepartmentFormSchema,
  IDepartmentResponse,
} from "../../../types/service/department.interface";

const API_URL = process.env.REACT_APP_API;

export const DepartmentService = createApi({
  reducerPath: "department",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ["department", "create-department"],
  endpoints: (builder) => ({
    // createDepartment --------------------------------->
    createDepartment: builder.mutation<
      any,
      { token: string; data: IDepartmentFormSchema }
    >({
      query: ({ token, data }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/department",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "department" }] : []),
    }),

    // findDepartment --------------------------------->
    findDepartments: builder.query<
      IDepartmentResponse,
      { token: string; query?: string }
    >({
      query: ({ token, query }) => {
        const url = query ? `/department${query}` : `/department`;
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url,
          method: "GET",
        };
      },
      providesTags: ["department"],
      transformResponse: (data: any) => {
        return {
          success: data.success,
          departments: data.departments.map((item: IDepartment) => ({
            deptId: item.deptId,
            deptName: item.deptName,
            hodName: item.hodName,
            hodMobile: item.hodMobile,
            employId: item.employId,
            branchName: item.branch.name,
            branchId: item.branch.branchID,
            created_at: item.created_at
              ? new Date(item.created_at).getFullYear()
              : new Date(),
          })),
        };
      },
    }),

    // get branch ByID--------------------------------->
    findOneDepartment: builder.query<
      IDepartmentResponse,
      { id: string; token: string }
    >({
      query: ({ id, token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/department/${id}`,
          method: "GET",
        };
      },
      providesTags: ["department"],
    }),

    // update branch --------------------------------->
    updateDepartment: builder.mutation<
      any,
      { token: string; data: any; id: string }
    >({
      query: ({ token, data, id }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/department/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "department" }] : []),
    }),

    deleteDepartment: builder.mutation<any, { token: string; id: string }>({
      query: ({ id, token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/department/${id}`,
          method: "delete",
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "department" }] : []),
    }),

    departmentModeratorOrUser: builder.query<
      branchModeratorUser,
      { token: string }
    >({
      query: ({ token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/admin/branches&moderators&users",
          method: "GET",
        };
      },
      providesTags: ["department"],
    }),
  }),
});

export const {
  useCreateDepartmentMutation,
  useDeleteDepartmentMutation,
  useDepartmentModeratorOrUserQuery,
  useFindDepartmentsQuery,
  useFindOneDepartmentQuery,
  useUpdateDepartmentMutation,
} = DepartmentService;
