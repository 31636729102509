import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createTargetDto } from "../../../types/admin/target.interface";
import { ITargets } from "../../../types/service/target.inerface";

const API_URL = process.env.REACT_APP_API;

export const TargetService = createApi({
  reducerPath: "target",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ["target", "get-target"],
  endpoints: (builder) => ({
    // createBranch --------------------------------->
    createTarget: builder.mutation<
      any,
      { token: string; data: createTargetDto }
    >({
      query: ({ token, data }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/target",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["target", "get-target"],
    }),

    // createBranch --------------------------------->
    targets: builder.query<ITargets, { token: string; role: string }>({
      query: ({ token, role }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/target?role=${role}`,
          method: "GET",
        };
      },
      providesTags: ["target"],
    }),
  }),
});

export const { useCreateTargetMutation, useTargetsQuery } = TargetService;
