import {

  IUpdateSurvey,
  IComplete,
  IApproveLeadDto,
} from "@/customtypes/service/operation.interface";
import { ResponseOperation } from "@/customtypes/service/operator.interface";
import {
  IErrorResponse,
  ISuccessResponse,
} from "@/customtypes/service/request.interface";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IReassign } from "./operator.service";

const API_URL = process.env.REACT_APP_API;

export const OperationSurvey = createApi({
  reducerPath: "OperationSurvey",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
  }),
  tagTypes: ["survey"],
  endpoints: (builder) => ({
    findAll: builder.query<ResponseOperation, { token: string }>({
      query: ({ token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/operation/survey",
          method: "GET",
        };
      },
      providesTags: ["survey"],
    }),

    update: builder.mutation<IErrorResponse | ISuccessResponse, IUpdateSurvey>({
      query: (data) => {
        return {
          headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-type": "application/json",
          },
          url: `/operation/survey/${data.operationId}`,
          method: "PATCH",
          body: {
            survey: data.survey,
          },
        };
      },
      invalidatesTags: ["survey"],
    }),

    complete: builder.mutation<ResponseOperation, IComplete>({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/survey/${data.operationId}/complete`,
          method: "GET",
        };
      },
      invalidatesTags: ["survey"],
    }),

    reassign: builder.mutation<ResponseOperation, IReassign>({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/survey/${data.operationId}/reassign`,
          method: "PATCH",
          body: {
            deadline: data.deadline,
            deadLineDated: data.deadLineDated,
          },
        };
      },
      invalidatesTags: ["survey"],
    }),
    approve: builder.mutation<ResponseOperation, IApproveLeadDto>({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/survey/${data.operationId}/approve`,
          method: "PATCH",
          body: {
            deadline: data.deadline,
            deadLineDated: data.deadLineDated,
            assignTo: data.assignTo,
            userId: data.userId,
          },
        };
      },
      invalidatesTags: ["survey"],
    }),
  }),
});

export const {
  useFindAllQuery,
  useApproveMutation,
  useCompleteMutation,
  useReassignMutation,
  useUpdateMutation,
} = OperationSurvey;
