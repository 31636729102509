import {
  AssignLeadDto,
  IApproveLeadDto,
} from "@/customtypes/service/operation.interface";
import {
  IOrderConfirmation,
  ResponseLeads,
  ResponseOperation,
  reassignSurveyDto,
} from "@/customtypes/service/operator.interface";
import {
  IErrorResponse,
  ISuccessResponse,
} from "@/customtypes/service/request.interface";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface AssignLeadRequestData extends AssignLeadDto {
  token: string;
}

export interface IReassign extends reassignSurveyDto {
  token: string;
  operationId: string;
}
const API_URL = process.env.REACT_APP_API;

export const OperatorService = createApi({
  reducerPath: "Operator",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
  }),
  tagTypes: ["closing", "occ"],
  endpoints: (builder) => ({
    // login --------------------------------->
    findOcc: builder.query<ResponseOperation, { token: string }>({
      query: ({ token }) => {
        // console.log(credentials)
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/operation/occ",
          method: "GET",
        };
      },
      providesTags: ["closing", "occ"],
    }),

    assignLead: builder.mutation<
      ISuccessResponse | IErrorResponse,
      AssignLeadRequestData
    >({
      query: (data) => {
        return {
          headers: {
            Authorization: `Bearer ${data.token}`,
            // "Content-type": "application/json",
          },
          url: `/operation/closing/assign`,
          method: "PATCH",
          body: {
            crmId: data.crmId,
            deadline: data.deadline,
            deadLineDated: data.deadLineDated,
            userId: data.userId,
          },
        };
      },
      invalidatesTags: ["closing", "occ"],
    }),

    findOneLead: builder.query<ResponseLeads, { token: string; id: string }>({
      query: ({ token, id }) => {
        // console.log(credentials)
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/operation/lead/${id}`,
          method: "GET",
        };
      },
      providesTags: ["occ"],
    }),

    updateOcc: builder.mutation<
      ISuccessResponse | IErrorResponse,
      { token: string; operationId: string; occ: IOrderConfirmation }
    >({
      query: (data) => {
        return {
          headers: {
            Authorization: `Bearer ${data.token}`,
            // "Content-type": "application/json",
          },
          url: `/operation/occ/${data.operationId}`,
          method: "POST",
          body: data.occ,
        };
      },
      invalidatesTags: ["occ"],
    }),

    occCompleted: builder.mutation<
      ISuccessResponse | IErrorResponse,
      { token: string; operationId: string }
    >({
      query: (data) => {
        return {
          headers: {
            Authorization: `Bearer ${data.token}`,
            // "Content-type": "application/json",
          },
          url: `/operation/occ/completed/${data.operationId}`,
          method: "GET",
        };
      },
      invalidatesTags: ["occ"],
    }),

    reAssignOcc: builder.mutation<ISuccessResponse | IErrorResponse, IReassign>(
      {
        query: ({ operationId, token, deadLineDated, deadline }) => {
          return {
            headers: {
              Authorization: `Bearer ${token}`,
              // "Content-type": "application/json",
            },
            // /operation/occ/:id/re-assign
            url: `/operation/occ/${operationId}/re-assign`,
            method: "PATCH",
            body: {
              deadline: deadline,
              deadLineDated: deadLineDated,
            },
          };
        },
        invalidatesTags: ["occ"],
      },
    ),
    approveOcc: builder.mutation<
      ISuccessResponse | IErrorResponse,
      IApproveLeadDto
    >({
      query: (data) => {
        return {
          headers: {
            Authorization: `Bearer ${data.token}`,
            // "Content-type": "application/json",
          },
          url: `/operation/occ/${data.operationId}/approve`,
          method: "PATCH",
          body: {
            assignTo: data.assignTo,
            userId: data.userId,
            deadLineDated: data.deadLineDated,
            deadline: data.deadline,
            operationId: data.operationId,
          },
        };
      },
      invalidatesTags: ["occ"],
    }),
  }),
});

export const {
  useFindOccQuery,
  useFindOneLeadQuery,
  useUpdateOccMutation,
  useOccCompletedMutation,
  useReAssignOccMutation,
  useApproveOccMutation,
} = OperatorService;
