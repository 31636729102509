import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { io, Socket } from "socket.io-client";
import { NotificationEvent } from "../../../types/core/notification.enum";
// import User from './user';

const API_URL = process.env.REACT_APP_API;

let socket: Socket;
function getSocket({ token }: { token: string }): Socket {
  if (!socket) {
    socket = io(process.env.REACT_APP_API as string, {
      withCredentials: true,
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return socket;
}

export const notificationService = createApi({
  reducerPath: "notification",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  endpoints: (builder) => ({
    sendNotification: builder.mutation<any, { token: string; data: any }>({
      queryFn: ({ token, data }) => {
        const socket = getSocket({ token });
        console.log({ token, data });
        return new Promise((resolve) => {
          socket.emit("create_notfication", data, (message: any) => {
            resolve({ data: message });
          });
        });
      },
    }),
    getNotification: builder.query<any, { token: string }>({
      // query: ({ token }) => {
      //     return {
      //         headers: {
      //             "Content-type": "application/json",
      //             "Authorization": `Bearer ${token}`

      //         },
      //         url: '/notification',
      //         method: 'GET',
      //     }
      // },
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(
        { token },
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData },
      ) {
        // console.log("notification query", { token })
        try {
          await cacheDataLoaded;
          const socket = getSocket({ token });
          // socket.on('connect', () => {
          //     socket.emit(NotificationEvent.requestAllNotification);
          // });

          socket.on("receive_notification", (message: any) => {
            // console.log(message)
            updateCachedData((draft) => {
              draft.push(message);
            });
          });

          await cacheEntryRemoved;

          socket.off("connect");
          socket.off(NotificationEvent.sendNotification);
          socket.off(NotificationEvent.receiveNotification);
        } catch {
          // if cacheEntryRemoved resolved before cacheDataLoaded,
          // cacheDataLoaded throws
        }
      },
    }),
  }),
});

export const { useGetNotificationQuery, useSendNotificationMutation } =
  notificationService;
