import "../../src/style/loader.css";

const Loader = () => {
  return (
    <div className="loader h-fit w-fit justify-center items-center bg-gray-800">
      <img
        src={require("../../src/assets/images/RT_Logo.png")}
        alt="Loading..."
      />
    </div>
  );
};
export default Loader;
