import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_URL = process.env.REACT_APP_API;

export const OrderConfirmationService = createApi({
  reducerPath: "OrderConfirmationService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
  }),
  tagTypes: ["closing"],
  endpoints: () => ({}),
});
