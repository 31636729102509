import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IUsers,
  ISingleUser,
  createUserDto,
} from "../../../../../types/service/user.interface";

const API_URL = process.env.REACT_APP_API;

export const UserService = createApi({
  reducerPath: "user",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ["users"],
  endpoints: (builder) => ({
    // createUser --------------------------------->
    createUser: builder.mutation<any, { data: createUserDto; token: string }>({
      query: ({ data, token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/user",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "users" }] : []),
      // invalidatesTags: ['users', 'create-user'],
    }),
    // createUser --------------------------------->
    AllUsers: builder.query<IUsers, { token: string; moderatorId: string }>({
      query: ({ token, moderatorId }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/users/${moderatorId}`,
          method: "GET",
        };
      },

      transformErrorResponse: (result) => result,
      providesTags: ["users"],

      // providesTags: ['users'],
    }),
    // getUserByID--------------------------------->
    userById: builder.query<ISingleUser, { id: string; token: string }>({
      query: ({ id, token }) => {
        // console.log(credentials.id)
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/user/${id}`,
          method: "GET",
        };
      },
      providesTags: ["users"],
    }),

    // createUser --------------------------------->
    updateUser: builder.mutation<any, { data: any; token: string }>({
      query: ({ data, token }) => {
        // console.log(credentials)
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/user",
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "users" }] : []),
    }),

    deleteUser: builder.mutation<any, { token: string; id: string }>({
      query: ({ id, token }) => {
        // console.log(credentials)
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/user/${id}`,
          method: "delete",
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "users" }] : []),
    }),
  }),
});

export const {
  useCreateUserMutation,
  useAllUsersQuery,
  useUserByIdQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = UserService;
