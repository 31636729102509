import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ITargets } from "../../../../../types/service/target.inerface";

const API_URL = process.env.REACT_APP_API;

export const UserTargetService = createApi({
  reducerPath: "target-user",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ["target"],
  endpoints: (builder) => ({
    // createBranch --------------------------------->
    target: builder.query<ITargets, { token: string }>({
      query: ({ token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/target/user`,
          method: "GET",
        };
      },
      providesTags: ["target"],
    }),
  }),
});

export const { useTargetQuery } = UserTargetService;
