import { z, string } from "zod";

export interface ISingleUser {
  success: boolean;
  users: IUser[];
}
export enum PermissionFlag {
  USER = "USER",
  MODERATOR = "MODERATOR",
  ADMIN = "ADMIN",
  OPERATION_MODERATOR = "OPERATION_MODERATOR",
  OPERATION_USER = "OPERATION_USER",
}

export interface IUsers {
  success: boolean;
  users: IUser[];
  total_moderators: number;
  total_users: number;
}

export interface IUser {
  target: number;
  role: string;
  email: string;
  branch: string;
  mobile: string;
  userID: string;
  username: string;
  created_at: Date;
  employeeID: string;
  designation: string;
  moderatorID: string | null;
  branchID: string;
  departmentId: string;
}

export const createModeratorSchema = z.object({
  username: string({ required_error: "Username is empty" }),
  designation: string({ required_error: "Username is empty" }),
  password: string({ required_error: "Username is empty" }),
  email: string({ required_error: "Username is empty" }),
  mobile: string({ required_error: "Username is empty" }),
  role: string({ required_error: "Username is empty" }),
  employeeID: string({ required_error: "employee id can not be empty" }),
  branchID: string({ required_error: "Branch can not be empty" }),
  branch: string({ required_error: "branch can not be empty" }),
  departmentId: string({ required_error: "department Id can't be empty" }),
});

export const createUserSchema = createModeratorSchema.extend({
  moderatorID: string({ required_error: "moderator id is missing" }),
});

export type createModeratorDto = z.infer<typeof createModeratorSchema>;

export type createUserDto = z.infer<typeof createUserSchema>;
