import {
  ClosingResponse,
  AssignLeadDto,
} from "@/customtypes/service/operation.interface";
import {
  IErrorResponse,
  ISuccessResponse,
} from "@/customtypes/service/request.interface";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface AssignLeadRequestData extends AssignLeadDto {
  token: string;
}

const API_URL = process.env.REACT_APP_API;

export const OperationManagerService = createApi({
  reducerPath: "OperationManager",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
  }),
  tagTypes: ["closing"],
  endpoints: (builder) => ({
    // login --------------------------------->
    closings: builder.query<ClosingResponse, { token: string }>({
      query: ({ token }) => {
        // console.log(credentials)
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/operation/closing",
          method: "GET",
        };
      },
      providesTags: ["closing"],
    }),

    assignClosingLead: builder.mutation<
      ISuccessResponse | IErrorResponse,
      AssignLeadRequestData
    >({
      query: (data) => {
        return {
          headers: {
            Authorization: `Bearer ${data.token}`,
            // "Content-type": "application/json",
          },
          url: `/operation/closing/assign`,
          method: "PATCH",
          body: {
            crmId: data.crmId,
            deadline: data.deadline,
            deadLineDated: data.deadLineDated,
            userId: data.userId,
          },
        };
      },
      invalidatesTags: ["closing"],
    }),
  }),
});

export const { useClosingsQuery, useAssignClosingLeadMutation } =
  OperationManagerService;
