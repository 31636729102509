import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_URL = process.env.REACT_APP_API;

export const AuthService = createApi({
  reducerPath: "authentication",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ["auth"],
  endpoints: (builder) => ({
    // login --------------------------------->
    login: builder.mutation<any, { [key: string]: any }>({
      query: (credentials) => {
        // console.log(credentials)
        return {
          headers: {
            "Content-type": "application/json",
          },
          url: "/auth",
          method: "POST",
          body: credentials,
        };
      },
      invalidatesTags: ["auth"],
    }),

    validToken: builder.mutation<any, { token: string }>({
      query: (credentials) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${credentials.token}`,
          },
          url: `/auth/verify`,
          method: "POST",
        };
      },
      invalidatesTags: ["auth"],
    }),
  }),
});

export const { useLoginMutation, useValidTokenMutation } = AuthService;
