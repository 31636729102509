import React, { useEffect, useState } from "react";

interface ContextType {
  theme: string | undefined;
  isDarkTheme: boolean;
  sidebarOpened: boolean;
  handleThemeChange: (e: string) => void;
  handleSetSidebarOpened: (e: boolean) => void;
  toggleSidebarOpened: () => void;
}

const initialState: ContextType = {
  theme: "dark",
  isDarkTheme: false,
  sidebarOpened: false,
  handleThemeChange: () => {},
  handleSetSidebarOpened: () => {},
  toggleSidebarOpened: () => {},
};

// const getInitialTheme = () => {
//   if (typeof window !== "undefined" && window.localStorage) {
//     // checks for a previous user preference in localStorage, and uses the browser's color scheme as a backup:
//     const storedPref = window.localStorage.getItem("color-theme");
//     if (typeof storedPref === "string") {
//       return storedPref;
//     }

//     const userMedia = window.matchMedia("prefers-color-scheme:dark");

//     if (userMedia.matches) {
//       return "dark";
//     }
//     return "dark"; // light theme as the default;
//   }
// };

export const ThemeContext = React.createContext<ContextType>(initialState);

interface Props {
  children: JSX.Element;
}

export const ThemeProvider = ({ children }: Props) => {
  const [theme, setTheme] = useState("light");

  const [sidebarOpened, setSideBarOpened] = useState(false);

  const rawSetTheme = (rawTheme: any) => {
    const root = window.document.documentElement;
    // const isDark = rawTheme === "light";
    root.classList.remove("dark");
    root.classList.add(rawTheme);
    localStorage.setItem("color-theme", rawTheme);
  };

  const isDarkTheme = theme === "dark";
  const handleThemeChange = (e: string) => {
    // console.log("hello");
    setTheme(e);
  };

  const handleSetSidebarOpened = (e: boolean) => {
    setSideBarOpened(e);
  };

  const toggleSidebarOpened = () => {
    setSideBarOpened((prev) => !prev);
  };

  useEffect(() => {
    rawSetTheme("light");
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        isDarkTheme,
        sidebarOpened,
        handleThemeChange,
        handleSetSidebarOpened,
        toggleSidebarOpened,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => React.useContext(ThemeContext);
