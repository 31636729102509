import {
  IComplete,
  IApproveLeadDto,
} from "@/customtypes/service/operation.interface";
import {
  IOperationLoadingDto,
  ResponseOperation,
} from "@/customtypes/service/operator.interface";
import {
  IErrorResponse,
  ISuccessResponse,
} from "@/customtypes/service/request.interface";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IReassign } from "./operator.service";

const API_URL = process.env.REACT_APP_API;

export const ProgressOperationService = createApi({
  reducerPath: "operationProgress",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
  }),
  tagTypes: ["progress"],
  endpoints: (builder) => ({
    findAllProgress: builder.query<ResponseOperation, { token: string }>({
      query: ({ token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/operation/progress",
          method: "GET",
        };
      },
      providesTags: ["progress"],
    }),

    updateProgress: builder.mutation<
      IErrorResponse | ISuccessResponse,
      { data: IOperationLoadingDto; token: string; operationId: string }
    >({
      query: (data) => {
        return {
          headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-type": "application/json",
          },
          url: `/operation/progress/${data.operationId}`,
          method: "PATCH",
          body: data.data,
        };
      },
      invalidatesTags: ["progress"],
    }),

    completeProgress: builder.mutation<ResponseOperation, IComplete>({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/progress/${data.operationId}/complete`,
          method: "GET",
        };
      },
      invalidatesTags: ["progress"],
    }),

    reassignProgress: builder.mutation<ResponseOperation, IReassign>({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/progress/${data.operationId}/reassign`,
          method: "PATCH",
          body: {
            deadline: data.deadline,
            deadLineDated: data.deadLineDated,
          },
        };
      },
      invalidatesTags: ["progress"],
    }),
    approveProgress: builder.mutation<ResponseOperation, IApproveLeadDto>({
      query: (data) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
          url: `/operation/progress/${data.operationId}/approve`,
          method: "PATCH",
          body: {
            deadline: data.deadline,
            deadLineDated: data.deadLineDated,
            assignTo: data.assignTo,
            userId: data.userId,
          },
        };
      },
      invalidatesTags: ["progress"],
    }),
  }),
});

export const {
  useApproveProgressMutation,
  useFindAllProgressQuery,
  useCompleteProgressMutation,
  useUpdateProgressMutation,
  useReassignProgressMutation,
} = ProgressOperationService;
