import { combineReducers, configureStore } from "@reduxjs/toolkit";

// Slices
import { appSlice } from "./slice/app.slice";
import { authSlice } from "./slice/auth.slice";
import { modalSlice } from "./slice/modal.slice";
import { notificationSlice } from "./slice/notification.slice";
import { dateFilterSlice } from "./slice/date.slice";

// redux-persist
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AuthService } from "../redux/service/auth.service";
import { UserService } from "../../features/departments/sell/Moderator/service/user.service";
import { CRMService } from "./service/crm.service";
import { BranchService } from "../../features/Admin/service/branch.service";
import { ModeratorService } from "../../features/Admin/service/moderator.service";
import { TargetService } from "./service/target.service";
import { TargetAdminService } from "../../features/Admin/service/target.admin.service";
import { notificationService } from "./service/notification.service";
import { AnnouncementService } from "./service/announcement.service";
import { UserTargetService } from "../../features/departments/sell/seller/service/target.user.service";
// Moderator Service
import { StatisticsService as mStatisticsService } from "../../features/departments/sell/Moderator/service/statistics.moderator.service";
import { AdminStatisticsService } from "../../features/Admin/service/statistics.admin.service";
import { DepartmentService } from "../../features/Admin/service/department.service";
import { OperationManagerService } from "./service/operation/operation.service";
import { OperatorService } from "./service/operation/operator.service";
import { OrderConfirmationService } from "./service/operation/occ.service";
import { OperationSurvey } from "./service/operation/survey.service";
import { DeliveryNoteService } from "./service/operation/deliveryNote.service";
import { ProgressOperationService } from "./service/operation/progress.service";
import { ProjectCompletionService } from "./service/operation/pcc.service";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // this will persist the 'counter' and 'auth' slices of the state
};

const rootReducer = combineReducers({
  [AuthService.reducerPath]: AuthService.reducer,
  [UserService.reducerPath]: UserService.reducer,
  [CRMService.reducerPath]: CRMService.reducer,
  [BranchService.reducerPath]: BranchService.reducer,
  [ModeratorService.reducerPath]: ModeratorService.reducer,
  [TargetService.reducerPath]: TargetService.reducer,
  [notificationService.reducerPath]: notificationService.reducer,
  [TargetAdminService.reducerPath]: TargetAdminService.reducer,
  [AnnouncementService.reducerPath]: AnnouncementService.reducer,
  [UserTargetService.reducerPath]: UserTargetService.reducer,
  [mStatisticsService.reducerPath]: mStatisticsService.reducer,
  [AdminStatisticsService.reducerPath]: AdminStatisticsService.reducer,
  [DepartmentService.reducerPath]: DepartmentService.reducer,
  [OperationManagerService.reducerPath]: OperationManagerService.reducer,
  [OperatorService.reducerPath]: OperatorService.reducer,
  [OrderConfirmationService.reducerPath]: OrderConfirmationService.reducer,
  [OperationSurvey.reducerPath]: OperationSurvey.reducer,
  [DeliveryNoteService.reducerPath]: DeliveryNoteService.reducer,
  [ProgressOperationService.reducerPath]: ProgressOperationService.reducer,
  [ProjectCompletionService.reducerPath]: ProjectCompletionService.reducer,
  app: appSlice.reducer,
  auth: authSlice.reducer,
  modal: modalSlice.reducer,
  notificationState: notificationSlice.reducer,
  dateFilter: dateFilterSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      AuthService.middleware,
      UserService.middleware,
      CRMService.middleware,
      BranchService.middleware,
      ModeratorService.middleware,
      TargetService.middleware,
      notificationService.middleware,
      TargetAdminService.middleware,
      AnnouncementService.middleware,
      UserTargetService.middleware,
      mStatisticsService.middleware,
      AdminStatisticsService.middleware,
      DepartmentService.middleware,
      OperationManagerService.middleware,
      OperatorService.middleware,
      OrderConfirmationService.middleware,
      OperationSurvey.middleware,
      DeliveryNoteService.middleware,
      ProgressOperationService.middleware,
      ProjectCompletionService.middleware,
    ]),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const getAuthToken = () => {
  const state = store.getState();
  return state.auth.token;
};
