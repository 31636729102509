import React from "react";

interface Props {
  date: string;
  year: string;
  month: string;
  day: string;

  onDateChangeHandler: (e: string) => void;
  onDayChangeHandler: (e: string) => void;
  onMonthChangeHandler: (e: string) => void;
  onYearChangeHandler: (e: string) => void;
  resetDate: () => void;
}

const defaultValue: Props = {
  date: "",
  day: "",
  month: "",
  year: "",
  resetDate: () => {},
  onDayChangeHandler: () => {},
  onMonthChangeHandler: () => {},
  onYearChangeHandler: () => {},
  onDateChangeHandler: () => {},
};

const DateFilterContext = React.createContext<Props>(defaultValue);

function DateProvider({ children }: { children: JSX.Element }) {
  const [_date, _setDate] = React.useState({
    value: "",
    day: "",
    month: "",
    year: "",
  });

  const onDateChangeHandler = (date: string) => {
    _setDate((prev) => ({ ...prev, value: date }));
  };

  const onDayChangeHandler = (day: string) => {
    _setDate((prev) => ({ ...prev, day }));
  };

  const onMonthChangeHandler = (month: string) => {
    _setDate((prev) => ({ ...prev, month }));
  };

  const onYearChangeHandler = (year: string) => {
    _setDate((prev) => ({ ...prev, year }));
  };

  const resetDate = () => {
    _setDate((prev) => ({ ...prev, day: "", month: "", value: "", year: "" }));
  };

  return (
    <DateFilterContext.Provider
      value={{
        day: _date.day,
        month: _date.month,
        year: _date.year,
        date: _date.value,
        resetDate,
        onDateChangeHandler,
        onDayChangeHandler,
        onMonthChangeHandler,
        onYearChangeHandler,
      }}
    >
      {children}
    </DateFilterContext.Provider>
  );
}

export default DateProvider;

export const useDateContext = () => React.useContext(DateFilterContext);
