import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./src/components/App/ThemeContext";
import { MantineProvider, createTheme } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import DateProvider from "./wrapper/provider/DateProvider";
import PrivateRoute from "./core/page/PrivateRoute";

import "@mantine/dropzone/styles.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";

const theme = createTheme({
  components: {},
});
const App = () => {
  return (
    <ThemeProvider>
      <MantineProvider theme={theme}>
        <ModalsProvider>
          <DateProvider>
            <BrowserRouter>
              <PrivateRoute />
            </BrowserRouter>
          </DateProvider>
        </ModalsProvider>
      </MantineProvider>
    </ThemeProvider>
  );
};

export default App;
