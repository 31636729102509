import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../../types/service/auth.interface";

interface IAuth {
  accessToken: string;
  refreshToken: string;
  user: IUser;
}

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: "",
    isAuthenticated: false,
    user: {} as IUser,
    refreshToken: "",
  },
  reducers: {
    loginSuccess: (state, action: PayloadAction<IAuth>) => {
      state.token = action.payload.accessToken;
      state.isAuthenticated = true;
      state.refreshToken = action.payload.refreshToken;
      state.user = action.payload.user;
    },
    logout: (state) => {
      state.token = "";
      state.isAuthenticated = false;
      state.refreshToken = "";
      state.user = {} as IUser;
    },
  },
});

export const { loginSuccess, logout } = authSlice.actions;
