import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IBranches,
  createBranchDto,
} from "../../../types/admin/branch.interface";
import { branchModeratorUser } from "../../../types/admin/branch&moderator&user";

const API_URL = process.env.REACT_APP_API;

export const BranchService = createApi({
  reducerPath: "branch",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ["branch", "create-branch"],
  endpoints: (builder) => ({
    // createBranch --------------------------------->
    createBranch: builder.mutation<
      any,
      { token: string; data: createBranchDto }
    >({
      query: ({ token, data }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/admin/branch",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "branch" }] : []),
    }),

    // createUser --------------------------------->
    AllBranches: builder.query<IBranches, { token: string }>({
      query: ({ token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/admin/branch",
          method: "GET",
        };
      },
      providesTags: ["branch"],
    }),

    // getbranchByID--------------------------------->
    branchById: builder.query<IBranches, { id: string; token: string }>({
      query: ({ id, token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/admin/branch/${id}`,
          method: "GET",
        };
      },
      providesTags: ["branch"],
    }),

    // update branch --------------------------------->
    updateBranch: builder.mutation<
      any,
      { token: string; data: any; id: string }
    >({
      query: ({ token, data, id }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/admin/branch/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "branch" }] : []),
    }),

    deleteBranch: builder.mutation<any, { token: string; id: string }>({
      query: ({ id, token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/admin/branch/${id}`,
          method: "delete",
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "branch" }] : []),
    }),

    branchModeratorUser: builder.query<branchModeratorUser, { token: string }>({
      query: ({ token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/admin/brnaches&moderators&users",
          method: "GET",
        };
      },
      providesTags: ["branch"],
    }),
  }),
});

export const {
  useAllBranchesQuery,
  useBranchByIdQuery,
  useBranchModeratorUserQuery,
  useCreateBranchMutation,
  useUpdateBranchMutation,
  useDeleteBranchMutation,
} = BranchService;
