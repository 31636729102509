import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IStatistics } from "../interface/statistics.interface";

const API_URL = process.env.REACT_APP_API;

export const AdminStatisticsService = createApi({
  reducerPath: "admin-statistics",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ["statistics"],
  endpoints: (builder) => ({
    // getStatics --------------------------------->
    getMStatistics: builder.query<
      IStatistics,
      { token: string; moderatorId: string }
    >({
      query: ({ token, moderatorId }) => {
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          url: `/admin/statistics/moderator/${moderatorId}`,
          method: "GET",
        };
      },
      providesTags: ["statistics"],
    }),

    getBranchStatistics: builder.query<
      IStatistics,
      { token: string; moderators: string }
    >({
      query: ({ token, moderators }) => {
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          url: `/admin/statistics/branch?moderators=${moderators}`,
          method: "GET",
        };
      },
      providesTags: ["statistics"],
    }),
  }),
});

export const {
  useGetMStatisticsQuery,
  useLazyGetMStatisticsQuery,
  useGetBranchStatisticsQuery,
  useLazyGetBranchStatisticsQuery,
} = AdminStatisticsService;
