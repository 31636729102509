import analytics from "../../src/assets/png/analytics-amico.png";

function NotFound() {
  return (
    <div className="w-fit h-fit flex justify-center items-center dark:text-gray-200">
      <img className="w-1/2 " src={analytics} alt="looking-for-data" />
    </div>
  );
}

export default NotFound;
