import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Notification = {
  notificationID: string;
  subject: string;
  body: string;
  userID: string;
  created_at: string;
  createrId: string;
  fileName: string;
  link: string;
  new: number;
};

interface InitialState {
  notifications: Notification[];
  isRead: boolean;
  noOfNewNotification: number;
}

const initialState: InitialState = {
  noOfNewNotification: 0,
  isRead: false,
  notifications: [],
};

export const notificationSlice = createSlice({
  name: "noti",
  initialState,
  reducers: {
    setAllNotifications: (state, action: PayloadAction<InitialState>) => {
      state.notifications = action.payload.notifications;
      state.isRead = action.payload.isRead;
      state.noOfNewNotification = action.payload.noOfNewNotification;
    },
    setNotifications: (
      state,
      action: PayloadAction<{ notifications: Notification[] }>,
    ) => {
      state.notifications = action.payload.notifications;
    },

    setIsRead: (state, action: PayloadAction<{ isRead: boolean }>) => {
      state.isRead = action.payload.isRead;
    },
    setNoOfNewNotifications: (
      state,
      action: PayloadAction<{ noOfNewNotification: number }>,
    ) => {
      state.noOfNewNotification = action.payload.noOfNewNotification;
    },
  },
});

export const {
  setAllNotifications,
  setIsRead,
  setNoOfNewNotifications,
  setNotifications,
} = notificationSlice.actions;
