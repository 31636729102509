import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    isCreateUserModalOpened: false,
    isAssignUserModalOpened: false,
    isEditUserModalOpened: false,
    selectedUserID: null || "",
    isCreateModalOPened: false,
    isEditModalOpened: false,
  },
  reducers: {
    setIsCreateUserModalOpen: (
      state,
      action: PayloadAction<{ value: boolean }>,
    ) => {
      state.isCreateUserModalOpened = action.payload.value;
    },
    setIsCreateModalOpen: (
      state,
      action: PayloadAction<{ value: boolean }>,
    ) => {
      state.isCreateModalOPened = action.payload.value;
    },
    setIsEditModalOpen: (state, action: PayloadAction<{ value: boolean }>) => {
      state.isEditModalOpened = action.payload.value;
    },
    setIsEditUserModalOpen: (
      state,
      action: PayloadAction<{ value: boolean }>,
    ) => {
      state.isEditUserModalOpened = action.payload.value;
    },
    setIsAssignUserModalOpen: (
      state,
      action: PayloadAction<{ value: boolean }>,
    ) => {
      state.isAssignUserModalOpened = action.payload.value;
    },
  },
});

export const {
  setIsCreateUserModalOpen,
  setIsCreateModalOpen,
  setIsAssignUserModalOpen,
  setIsEditUserModalOpen,
  setIsEditModalOpen,
} = modalSlice.actions;
