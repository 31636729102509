import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ISingleUser,
  IUsers,
  createModeratorDto,
} from "../../../types/service/user.interface";
import { IBranchModerators } from "../interface/branchModerator.interface";

const API_URL = process.env.REACT_APP_API;

export const ModeratorService = createApi({
  reducerPath: "moderator",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  tagTypes: ["get-moderator", "create-moderator"],
  endpoints: (builder) => ({
    // createUser --------------------------------->
    createModerator: builder.mutation<
      any,
      { data: createModeratorDto; token: string }
    >({
      query: ({ data, token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/admin/moderator",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["create-moderator", "get-moderator"],
    }),
    // createUser --------------------------------->
    AllModerator: builder.query<IUsers, { token: string }>({
      query: ({ token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/admin/moderator",
          method: "GET",
        };
      },
      providesTags: ["get-moderator"],
      transformResponse: (data: any) => {
        return {
          success: data.success,
          users: data.users.map((item: any) => ({
            userID: item.userID,
            username: item.username,
            designation: item.designation,
            mobile: item.mobile,
            role: item.role,
            email: item.email,
            moderatorID: item.moderatorID,
            employeeID: item.employeeID,
            branch: item.branch,
            deptName: item.department.deptName,
            deptId: item.department.deptId,
          })),
          total_moderators: data.total_moderators,
          total_users: data.total_users,
        };
      },
    }),
    // getUserByID--------------------------------->
    moderatorById: builder.query<ISingleUser, { id: string; token: string }>({
      query: ({ id, token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/admin/moderator/${id}`,
          method: "GET",
        };
      },
      providesTags: ["get-moderator"],
    }),
    // getUserBybranchID--------------------------------->
    moderatorByBranchId: builder.query<
      IBranchModerators,
      { branchId: string; token: string }
    >({
      query: ({ branchId, token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/admin/moderator/branch/${branchId}`,
          method: "GET",
        };
      },
      providesTags: ["get-moderator"],
    }),

    // createUser --------------------------------->
    updateModerator: builder.mutation<any, { data: any; token: string }>({
      query: ({ data, token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: "/admin/moderator",
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["get-moderator"],
    }),

    deleteModerator: builder.mutation<any, { token: string; id: string }>({
      query: ({ id, token }) => {
        return {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          url: `/admin/moderator/${id}`,
          method: "delete",
        };
      },
      invalidatesTags: ["get-moderator"],
    }),
  }),
});

export const {
  useAllModeratorQuery,
  useCreateModeratorMutation,
  useModeratorByIdQuery,
  useUpdateModeratorMutation,
  useDeleteModeratorMutation,
  // get moderator by id
  useModeratorByBranchIdQuery,
} = ModeratorService;
